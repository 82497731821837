import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;
const Aurl = process.env.REACT_APP_LAMDA_URL;

const sendMail = (email, OTP) => {
  fetch(`${Aurl}/default/sendContactForm`, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify({
      receiverMail: email,
      subject: "Password Reset",
      receiverName: "admin",
      message: `Dear user,

We have received a request to reset your account password. Please use the following One-Time Password (OTP) to reset your password:

OTP: ${OTP}

If you did not initiate this request, please ignore this email. Your password will remain unchanged.
		
Regards,
Team SetuGram
https://www.setugram.com`,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
};

const Signup = (user) => {
  return axios
    .post(`${url}/auth/local/register`, {
      name: user.username,
      username: user.email,
      email: user.email,
      contact: user.contact,
      password: user.password,
    })
    .then((response) => {
      alert("Registered successfully. Please login");
    })
    .then((response) => response);
};

const Login = (user, setModalIsOpen, setIsLogged) => {
  const { identifier, password } = user;
  axios
    .post(`${url}/auth/local`, {
      identifier: identifier,
      password: password,
    })
    .then((response) => {
      if (response.data.statusCode === 400) {
        setModalIsOpen(true);
        setIsLogged(false);
        alert("Invalid Credentials or password");
      }
      if (response.data.jwt) {
        localStorage.setItem("employeekey", response.data.user.id);
        localStorage.setItem("employeeid", response.data.jwt);
        setModalIsOpen(false);
        setIsLogged(true);
      }
    })
    .then((response) => response);
};

const ConfirmEmailId = (
  email,
  setSecretCodeModalIsOpen,
  setForgotModalIsOpen,
  setSendOTPnumber
) => {
  axios
    .get(`${url}/users?email=${email}`)
    .then((response) => {
      if (response.data.statusCode === 400) {
        alert("Something went wrong, Please try again");
      }
      if (response.data.length > 0) {
        const userEmail = response?.data[0]?.email;
        const otp = Math.floor(100000 + Math.random() * 900000);
        sendMail(userEmail, otp);
        setSendOTPnumber(otp);
        setSecretCodeModalIsOpen(true);
        setForgotModalIsOpen(false);
      } else {
        alert(
          "No user was found with the provided email address. Please proceed with the registration process."
        );
      }
    })
    .then((response) => response);
};

const ResetPassword = (user) => {
  const { userId, password } = user;
  axios
    .put(`${url}/users/${userId}`, {
      password: password,
    })
    .then((response) => {
      if (response.data.statusCode === 400) {
        alert("Something went wrong, Please try again");
      }
      if (response.data) {
      }
    })
    .then((response) => response);
};

const Logout = () => {
  localStorage.removeItem("user");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { Signup, Login, Logout, ResetPassword, ConfirmEmailId };
