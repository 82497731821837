import React, { useState, useEffect } from "react";
import "../AddNew/addNew.css";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
const url = process.env.REACT_APP_BASE_URL;

export default function AddNew() {
  const token = localStorage.getItem("employeeid");
  const employeeid = localStorage.getItem("employeekey");
  const [getduration, setGetDuration] = useState([]);
  const [getlocation, setGetLocation] = useState([]);
  const [tags, setTags] = useState([]);

  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState("");
  const [startdate, setStartDate] = useState("");
  const [stipend, setStipend] = useState("");
  const [eligibility, setEligibility] = useState("");
  const [aboutTheCompany, setAboutTheCompany] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [perk, setPerk] = useState("");

  const AddAssignment = () => {
    fetch(`${url}/internships`, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        stipendPerMonth: stipend,
        employer: employeeid,
        startDate: startdate,
        perks: perk,
        title: title,
        location: location,
        tags: category,
        description: description,
        eligibility: eligibility,
        aboutCompany: aboutTheCompany,
        duration: duration,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert("Successfully Added");
        console.log("okk");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetch(`${url}/z-tags-internships`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setTags(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    fetch(`${url}/z-internship-durations`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialLocation) => {
        setGetDuration(initialLocation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(`${url}/locations`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((initialLocation) => {
        setGetLocation(initialLocation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="addNew-con">
        <h1 className="addNew-head">Add New Internships</h1>
        <>
          <div className="addNew-subcon1 row">
            <div className="col-md-6 col-lg-6">
              <label htmlFor="fInput">Title</label>
              <br />
              <input
                type="text"
                id="fInput"
                name="title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <br />
              <label htmlFor="fInput">Duration (Months)</label>
              <br />
              <SubInputD
                id="fInput"
                onChange={(e) => setDuration(e.target.value)}
              >
                {getduration.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.months}
                  </option>
                ))}
              </SubInputD>
              <br />
              <label htmlFor="fInput">Stipend</label>
              <br />
              <input
                type="text"
                id="fInput"
                name="stipend"
                onChange={(e) => setStipend(e.target.value)}
              />
              <br />
              <label htmlFor="fInput">Description</label>
              <br />
              <textarea
                rows="7"
                id="fInput2"
                cols="100"
                name="description"
                onChange={(e) => setDescription(e.target.value)}
              />
              <br />
              <label htmlFor="fInput">Perks</label>
              <br />
              <textarea
                rows="7"
                id="fInput2"
                cols="90"
                name="description"
                onChange={(e) => setPerk(e.target.value)}
              />
              <br />
            </div>
            <div className="col-md-6 col-lg-6">
              <label htmlFor="fInput">Location</label>
              <br />
              <SubInputD
                id="fInput"
                onChange={(e) => setLocation(e.target.value)}
              >
                {getlocation.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </SubInputD>
              <br />
              <label htmlFor="fInput">Category</label>
              <br />
              <div className="f2Input-con row">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={tags}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  onChange={(event, newValue) => {
                    setCategory(
                      newValue.map((ele) => {
                        return ele.id;
                      })
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Search ..."
                      placeholder="Favorites"
                    />
                  )}
                />
              </div>
              <br />
              <label htmlFor="fInput">Start Date</label>
              <br />
              <input
                type="date"
                id="fInput"
                name="description"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <br />
              <label htmlFor="fInput">Education Criteria </label>
              <br />
              <textarea
                rows="7"
                id="fInput2"
                cols="90"
                name="fInput"
                onChange={(e) => setEligibility(e.target.value)}
              />
              <br />
              <label htmlFor="fInput">About the Company</label>
              <br />
              <textarea
                rows="7"
                id="fInput2"
                cols="90"
                name="fInput"
                onChange={(e) => setAboutTheCompany(e.target.value)}
              />
              <br />
            </div>
          </div>
        </>
        <button className="addNew-mainB" onClick={() => AddAssignment()}>
          Submit
        </button>
      </div>
    </>
  );
}

const SubInputD = styled.select`
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  border-radius: 5pxs;
  width: 100%;
  height: 41px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
  @media (max-width: 480px) {
  }
`;
