import React from "react";
import { Redirect, Route } from "react-router";
import jwtDecode from "jwt-decode";

const PrivateRoute = ({ children, ...rest }) => {
  function isAuthenticated() {
    const CLIENTKEY = localStorage.getItem("employeeid");
    try {
      if (jwtDecode(CLIENTKEY).exp < Date.now() / 1000) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  const CHECK = isAuthenticated();
  console.log(CHECK);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        CHECK === true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
