import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ProfileWrapper = styled.div``;
export const IMG = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const Nav = styled.nav`
  background: #ffffff;
  padding: 0.3em 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid rgb(200, 200, 200);
  z-index: 12;
  position: fixed;
  top: 0;
  width: 100%;
  @media (max-width: 480px) {
    /* height: 40px !important; */
  }
`;

export const NavLinkX = styled.p`
  color: #808080;
  align-items: center;
  text-decoration: none;
  padding: 0 1em;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  margin-top: auto;
  margin-left: 1rem;
  margin-bottom: auto;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const NavBeforeLogin = styled.div`
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 0 0 3em;
  height: 100%;
  cursor: pointer;
  &:active {
    color: #000000;
  }
  left: calc(50% - 50px / 2 - 298px);
  top: 0.71%;
  bottom: 98.84%;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2em;
  line-height: 22px;
`;

export const NavLink = styled(Link)`
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 0 0 3em;
  height: 100%;
  cursor: pointer;
  &:active {
    color: #000000;
  }
  left: calc(50% - 50px / 2 - 298px);
  top: 0.71%;
  bottom: 98.84%;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2em;
  line-height: 22px;
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: start; */
  /* margin-right: -24px; */
  @media screen and (max-width: 768px) {
    /* display: none; */
  }
`;

export const NavBtn = styled.nav`
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  padding: 0 0 0 3em;
  cursor: pointer;
  left: calc(50% - 50px / 2 - 298px);
  bottom: 98.84%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.15em;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  &:active {
    color: #000000;
  }

  @media screen and (max-width: 768px) {
  }
`;

export const NavBtnLink = styled.button`
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff8ad;
    color: #555;
  }
  margin: 0 0 0 3em;
  width: 100px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.3em;
  background-color: #fff56c;
  color: #000;
  text-decoration: none;
  outline: none;
  border: none;
  @media screen and (max-width: 768px) {
    width: 80px;
    height: 35px;
    font-size: 1em;
  }
`;
