import React, { useState, useEffect } from "react";
import "../Components/in.css";
import { useHistory } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import {
  Wrapper,
  Section,
  MainTitile,
  Title,
  CompanyName,
  Details,
  DetailSection,
  DetailTitle,
  DetailInfo,
  FontAw,
  InternCard,
  FirstCon,
  Inpicon,
} from "./InternshipCardElements";
import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;

export default function InternshipCard({}) {
  const employeeid = localStorage.getItem("employeekey");

  const [intershipdetails, setIntershipDetails] = useState([]);
  const [state2, setstate2] = useState(false);
  const history = useHistory();

  const changeState2 = () => {
    if (state2 === false) {
      setstate2(true);
    }
    if (state2 === true) {
      setstate2(false);
    }
  };

  useEffect(() => {
    fetch(`${url}/internships?employer.id=${employeeid}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setIntershipDetails(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [state2]);

  const changeActive = (id, state) => {
    axios
      .put(`${url}/internships/${id}`, {
        isActive: state,
        // email: setemail,
        // linkedInProfile: setlinkedind,
        // contact: setcontact,
        // skills: settags,
      })
      .then((response) => {
        console.log(response);
        // console.log(data)
        changeState2();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Wrapper>
      <MainTitile>See Jobs</MainTitile>
      {intershipdetails.length > 0 &&
        intershipdetails.map((item, index) => (
          <InternCard key={index} to={`/internships/${item.id}`}>
            <Section>
              <Title>{item.title}</Title>
              <FirstCon>
                <CompanyName>{item.employer.username}</CompanyName>
                <FontAw>
                  <div
                    className="col-md-10 Pdetails"
                    to=""
                    onClick={() =>
                      history.push("/see-jobs/applicants/" + item.id)
                    }
                    exact
                  >
                    View Applicants
                  </div>
                  <Inpicon>
                    <label class="switch">
                      <input
                        type="checkbox"
                        defaultChecked={item.isActive}
                        onChange={() => changeActive(item.id, !item.isActive)}
                      />
                      <span class="slider round"></span>
                    </label>
                  </Inpicon>
                  <Inpicon>
                    <AiFillEdit
                      className="InPicon"
                      onClick={() => history.push("/updateintern/" + item.id)}
                    />
                  </Inpicon>
                </FontAw>
              </FirstCon>

              <Details>
                <DetailSection>
                  <DetailTitle>Start Date</DetailTitle>
                  <DetailInfo>{item.startDate}</DetailInfo>
                </DetailSection>
                <DetailSection>
                  <DetailTitle>Duration (Months)</DetailTitle>
                  <DetailInfo>
                    {item.duration.months ? item.duration.months : null} months
                  </DetailInfo>
                </DetailSection>
                <DetailSection>
                  <DetailTitle>Stipend</DetailTitle>
                  <DetailInfo>Rs.{item.stipendPerMonth} p.m</DetailInfo>
                </DetailSection>
                <DetailSection>
                  <DetailTitle>Location</DetailTitle>
                  <DetailInfo>
                    {item.location.name ? item.location.name : null}
                  </DetailInfo>
                </DetailSection>
              </Details>

              <div className="appId">
                <div className="appId-subcon1 row">
                  <p className="appId-head col-sm-2">Skills :</p>
                  {item.tags.map((des) => (
                    <div className="col-sm-10 appId-link1">
                      <p href="x">{des.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </Section>
          </InternCard>
        ))}
      {intershipdetails.length === 0 && (
        <div
          style={{
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>No Internship found</h2>
        </div>
      )}
    </Wrapper>
  );
}
