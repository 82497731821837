import Styled from "styled-components";
import { Link } from "react-router-dom";
export const Section = Styled.section`
    margin: 5em 10.5em;
    height: 85%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.51);
    @media (max-width: 480px) {
        margin: 0.5em;
    }
`;
export const CompanyName = Styled.p`
    font-size: 1.3em;
    line-height: 1.5em;
    padding: 1em 1em 0;
    font-weight:500;
    color: rgba(0, 0, 0, 0.51);
    @media (max-width: 480px) {
        font-size: 14px;
        line-height: 17px;
    }
`;
export const MainTitile = Styled.h1`
    font-size: 36px;
    line-height: 1.5em;
    color: rgba(0, 0, 0, 1);
    margin: 0 8%;
    @media (max-width: 480px) {
    font-size: 23px;
        line-height: 17px;
        margin: 1em 1em;
    }
`;
export const DetailTitle = Styled.h5`
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: 500;
    @media (max-width: 480px) {
        font-size: 12px;
        line-height: 14px;
    }
`;
export const FontAw = Styled.div`
    display: flex;
	font-size: 1.3em;
	padding-right: 1.5em;
	@media (max-width: 500px) {
		text-align: left;
	font-size: 12px;
	}
`;
export const Inpicon = Styled.button`
    background: none;
    background-color:none;
    border: none;
    decoration:none;
	font-size: 25px;
	padding-right: 1.5em;
	@media (max-width: 500px) {
		text-align: left;
	padding:0.5em 0.5em 0;
	}
`;

export const DetailInfo = Styled.p`
    color: rgba(0, 0, 0, 0.5);font-size: 1.3em;
    margin-top: .5em;
    line-height: 1.4em;
    font-weight:500;
    @media (max-width: 480px) {
        font-size: 12px;
    line-height: 14px;
    }
`;
export const SkillsSection = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1.5em;
    padding: 0 1.5em;
    gap: 1em;
`;
export const ViewDetailsSection = Styled.p`
        display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    margin-top: 1em;
    padding: 0 1.5em;
    line-height: 1.4em;
    font-size: 1.1em;    
`;
export const LinkS = Styled(Link)`
    font-size: 1em;
    line-height: 1.4em;
    font-weight: 500;
	cursor: pointer;
	text-decoration: none;
    color: #000;
    height: 100%;
    width: 100px;
        &:hover{
	text-decoration: none;
        }
`;
export const InternCard = Styled.div`
	cursor: pointer;
	text-decoration: none;
    color: #000;
    height: 100%;
`;

export const SkillButton = Styled.section`
    background-color: #FFF56C;
    border-radius: 5px;
    text-align: center;
    font-size: 1.05em;
    font-weight: 500;
    flex: auto;
    width: fit-content;
    padding: 10px;
    @media (max-width: 480px) {
        font-size: 15px;
        padding: 5px;
    }
`;
export const DetailSection = Styled.section`

`;
export const FirstCon = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
`;
export const FaRegEdit = Styled.svg`
    background: #000000;
`;
export const Details = Styled.div`
    width: 75%;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5em;
    margin-top: 1.5em;
    @media (max-width: 480px) {
    width: 100%;
    padding: 0 1em;
    }
`;
export const Title = Styled.h3`
    padding: 0.5em 1em;
    margin: 0;
    font-size: 1.5em;
    background-color: #FFF56C;
    a {
        text-decoration: none;
        color: black;
    }
    @media (max-width: 480px) {
        font-size: 14px;
        line-height: 17px;
    }
`;
export const Wrapper = Styled.section`
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 8% 0 0;
    
    @media (max-width: 480px) {
        font-size: 14px;
        margin: 2em 0;
    }
`;
