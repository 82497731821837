import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from "../src/Components/Navbar/index";
import InternshipCard from './Components/InternshipCard';
import AddNew from './Components/AddNew/addNew';
import Application from './Components/Application/Application';
import AppId from './Components/AppId/AppId';
import RegisterModal from './Components/RegisterModal';
import LoginModal from './Components/LoginModal';
import Footer from "../src/Components/Footer";
import UpdateInternNew from './Components/Update/updateIntern';
import PrivateRoute from './Components/PrivateRoute';
import TernofUse from './Components/termuse';
import RefundPolicy from './Components/returnpolicy';
import PrivatePolicy from './Components/privacypolicy';
import Home from './Components/Home';
import './App.css';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
          <Switch>
            <Route exact path="/">
              <Home></Home>
            </Route>
              <PrivateRoute exact path="/add-new">
                <AddNew></AddNew>
              </PrivateRoute>
              <PrivateRoute exact path="/see-jobs">
                <InternshipCard></InternshipCard>
              </PrivateRoute>
              <PrivateRoute exact path="/updateintern/:id">
                <UpdateInternNew></UpdateInternNew>
              </PrivateRoute>
              <PrivateRoute exact path="/see-jobs/applicants/:id">
                <Application></Application>
              </PrivateRoute>
              <PrivateRoute exact path="/see-jobs/applicantsuser/:id">
                <AppId></AppId>
              </PrivateRoute>
            <Route path='/private-policy' component={PrivatePolicy} />
						<Route path='/term-of-use' component={TernofUse} />
						<Route path='/refund-policy' component={RefundPolicy} />
            <Route exact path="*">
              <Home></Home>
            </Route>
          </Switch>
        <Footer/> 
      </BrowserRouter>
    </div>
  );
}

export default App;
