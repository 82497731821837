import React, { useState } from "react";
import styled from "styled-components";
import Auth from "../ApiServices/Auth";
import Modal from "react-modal";
Modal.setAppElement("#root");
export default function SecretCodeModal(props) {
  const {
    SecretCodeModalIsOpen,
    setSecretCodeModalIsOpen,
    SendOTPnumber,
    setResetPasswordModalIsOpen,
  } = props;
  const [Code, setCode] = useState("");

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleCode = async () => {
    if (Number(Code) === SendOTPnumber) {
      setSecretCodeModalIsOpen(false);
      setResetPasswordModalIsOpen(true);
    } else {
      alert("Please enter a valid OTP to continue.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCode();
  };
  return (
    <ModalS
      isOpen={SecretCodeModalIsOpen}
      onRequestClose={() => setSecretCodeModalIsOpen(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      <div>
        <Form onSubmit={handleSubmit}>
          <Label>Verification</Label>
          <LabelTwo>
            Enter the verification code we just sent you on your emailId
          </LabelTwo>
          <FormField>
            <InputField
              placeholder="Enter your 6 digit code"
              value={Code}
              type="number"
              maxlength="4"
              onChange={handleCodeChange}
            ></InputField>
          </FormField>
          <ButtonWrapper>
            <Button type="submit">Verify</Button>
          </ButtonWrapper>
          <NewAccountWrapper>
            If you didn't receive the code ? Please try again
          </NewAccountWrapper>
        </Form>
      </div>
    </ModalS>
  );
}

const Sbutton = styled.button`
  outline: none;
  color: #004;
  background-color: #fff;
  font-size: 16px;
  border: none;
  &:hover {
    color: #00a;
  }
`;

const ModalS = styled(Modal)`
  width: 526px;
  min-height: 400px;
  max-height: 400px;
  padding: 2em 4em 0 4em;
  border: black 1px solid;
  margin: 4em;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  margin-top: 2em;
  @media (max-width: 480px) {
    font-size: 12px;
    width: 280px;
    max-height: 350px;
    min-height: 350px;
    margin: 0;
    padding: 2em;
  }
`;

const Form = styled.form`
  text-align: center;
  z-index: 1000;
  @media (max-width: 480px) {
  }
`;

const NewAccountWrapper = styled.p`
  margin-top: 1em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const Button = styled.button`
  padding: 0.5em 3em;
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
  background-color: #fff56c;
  margin-top: 2em;
`;

const ButtonWrapper = styled.div``;

const InputField = styled.input`
  width: 100%;
  padding: 1em;
  margin-top: 0.8em;
  border-radius: 10px;
  @media (max-width: 480px) {
    padding: 0.5em;
    margin-top: 0.2em;
  }
`;

const InputLabel = styled.label`
  font-size: 1.3em;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const FormField = styled.section`
  text-align: left;
  margin-top: 1.5em;
`;

const Label = styled.label`
  text-align: center;
  width: 100%;
  font-size: 1.9em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const LabelTwo = styled.label`
  text-align: center;
  width: 100%;
  font-size: 0.8em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;
