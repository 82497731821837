import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Application/Application.css";
import ApplCard from "./ApplicationCard";
import { VscSettings } from "react-icons/vsc";
const url = process.env.REACT_APP_BASE_URL;

export default function Application() {
  const employeeid = localStorage.getItem("employeekey");
  const [intershipdetails, setIntershipDetails] = useState();
  const InternId = useParams("id");

  useEffect(() => {
    fetch(`${url}/internships/${InternId.id}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => {
        setIntershipDetails(data);
        console.log(data.applicants[0].id);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <div className="appl-con">
        <div className="appl-subcon1 row d-flex">
          <h1 className="appl-head mr-auto">List of Applicants</h1>
          <div className="AppIcon">
            <VscSettings />
          </div>
        </div>
        <div className="row pro-card-con">
          {intershipdetails !== undefined &&
            intershipdetails.applicants.length > 0 && (
              <ApplCard internship={intershipdetails.applicants} />
            )}
        </div>
      </div>
    </>
  );
}
