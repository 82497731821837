import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaRegHeart } from "react-icons/fa";
import "../AppId/AppId.css";
const url = process.env.REACT_APP_BASE_URL;

export default function AppId() {
  const employeeid = localStorage.getItem("employeekey");
  const [intershipdetails, setIntershipDetails] = useState();
  const InternId = useParams("id");
  return (
    <>
      {intershipdetails !== undefined && (
        <div className="AppId-con">
          <div className="appId-subcon1">
            <h1 className="appId-head">Job ID</h1>
          </div>
          <div className="appId-subcon2">
            <div className="appId">
              <div className="appId-subcon row">
                <h3 className="appId-heading">{intershipdetails.username}</h3>
                <div className="Appl-Icon">
                  <span id="heart">
                    <FaRegHeart class="fa fa-heart-o" aria-hidden="true" />
                  </span>
                </div>
              </div>
              <div className="appId-subcon3 row">
                <p className="appId-head col-sm-2">Emails :</p>
                <div className="appId-link2">
                  <p href="x">{intershipdetails.email}</p>
                </div>
              </div>
              <div className="appId-subcon1 row">
                <p className="appId-head col-sm-2">Skills :</p>
                {intershipdetails.skills.map((des) => (
                  <div className="col-sm-10 appId-link1">
                    <p href="x">{des.name}</p>
                  </div>
                ))}
              </div>
              <div className="appId-subcon3 row">
                <p className="appId-head col-sm-2">Details :</p>
                <div className="appId-link2">
                  <p href="x"></p>
                </div>
              </div>
              <div className="appId-subcon2 row">
                <p className="appId-head col-sm-2">Experience :</p>
                <div className="appId-link2">
                  <p href="x"></p>
                </div>
              </div>
              <div className="appId-subcon4 row">
                {intershipdetails.resume && (
                  <a
                    href={`${url}${intershipdetails.resume.url}`}
                    target="_blank"
                  >
                    <button className="appId-liB">resume.pdf</button>
                  </a>
                )}
                {intershipdetails.linkedInProfile && (
                  <a href={intershipdetails.linkedInProfile} target="_blank">
                    <button className="appId-liB">Linkedin profile</button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
