import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { VscSettings } from "react-icons/vsc";
import Logo from "../Assests/logo_strapi.png";
const url = process.env.REACT_APP_BASE_URL;

export default function Home() {
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={Logo}
          style={{
            height: "10rem",
            width: "10rem",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "2rem",
          }}
        />
        <div className="appl-subcon1 row d-flex">
          <h1 className="appl-head mr-auto">Welcome to Setugram Employee</h1>
        </div>
      </div>
    </>
  );
}
