import React, {useState, useEffect} from "react";
import "../AddNew/addNew.css";
import {useParams } from "react-router-dom";
import styled from "styled-components";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
const url = process.env.REACT_APP_BASE_URL;

export default function UpdateInternNew() {

    const token = localStorage.getItem('employeeid');
    const employeeid = localStorage.getItem('employeekey');
    const [getdetails, setgetDetails] = useState([]);
    const [getduration, setGetDuration] = useState([]);
    const [getlocation, setGetLocation] = useState([]);
    const [tags, setTags] = useState([]);

    const InternId = useParams('id');

    useEffect(() => {
        fetch(`${url}/internships/${InternId.id}`,{
            method:"get",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            }
		})
		.then(res => res.json())
		.then(data =>{
		    setgetDetails(data)
		})
		.catch(e => {
			console.log(e);
		})
    },[])

    
    const [title, setTitle] = useState(getdetails.title);
    const [duration, setDuration] = useState(getdetails.duration);
    const [startdate, setStartDate] = useState(getdetails.startDate);
    const [stipend, setStipend] = useState(getdetails.stipendPerMonth);
    const [eligibility, setEligibility] = useState(getdetails.eligibility);
    const [location, setLocation] = useState(getdetails.location);
    const [category, setCategory] = useState(getdetails.tags);
    const [description, setDescription] = useState(getdetails.description);

    const AddAssignment = () =>{
        fetch(`${url}/internships/${InternId.id}`,{
            method:"put",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${token}`,
            },
            body:JSON.stringify({
                "stipendPerMonth": stipend,
                "employer": employeeid,
                "startDate": startdate,
                "title": title,
                "location": location,
                "tags": category,
                "description": description,
                "eligibility": eligibility,
                "duration": duration
            })
		})
		.then(res => res.json())
		.then(data =>{
		    console.log("okk")
		})
		.catch(e => {
			console.log(e);
		})
    }

	useEffect(() => {
		fetch(`${url}/z-tags-internships`,{
            method:"get"
		})
		.then(res => res.json())
		.then(data =>{
		    setTags(data);
		})
		.catch(e => {
			console.log(e);
		})
	}, []);

    useEffect(() => {
		fetch(`${url}/z-internship-durations`,{
				method:"get"
			})
		.then(res => res.json())
		.then(initialLocation =>{
			setGetDuration(initialLocation);
		})
		.catch(error => {
			console.log(error)
		})
	}, [])

    useEffect(() => {
		fetch(`${url}/locations`,{
				method:"get"
			})
		.then(res => res.json())
		.then(initialLocation =>{
			setGetLocation(initialLocation);
		})
		.catch(error => {
			console.log(error)
		})
	}, [])

    console.log(getdetails.duration)

	return (
		<>
            <div className="addNew-con">
                <h1 className="addNew-head">Update Internships</h1>
                    <>
                    <div className="addNew-subcon1 row">
                        <div className="col-md-6 col-lg-6">
                                <label htmlFor="fInput">Title</label>
                                <br/>
                                <input type="text" id="fInput" name="title" defaultValue={getdetails.title} onChange={(e) => setTitle(e.target.value)}/>
                                <br/>
                                <label htmlFor="fInput">Duration</label>
                                <br/>
                                <SubInputD onChange={(e)=> setDuration(e.target.value)}>
                                {
                                    getduration.map((item, index) =>
                                        <option key={index} value={item.id} >{item.months}</option>
                                    )
                                }
                                </SubInputD>
                                <br/>
                                <label htmlFor="fInput">Stipend</label>
                                <br/>
                                <input type="text" id="fInput" name="stipend" defaultValue={getdetails.stipendPerMonth} onChange={(e) => setStipend(e.target.value)}/>
                                <br/>
                                <label htmlFor="fInput">Description</label>
                                <br/>
                                <input type="text" id="fInput" name="description" defaultValue={getdetails.description} onChange={(e) => setDescription(e.target.value)}/>
                                <br />
                        </div>
                        <div className="col-md-6 col-lg-6">
                                <label htmlFor="fInput">Eligibility</label>
                                <br/>
                                <input type="text" id="fInput" name="fInput" defaultValue={getdetails.eligibility} onChange={(e) => setEligibility(e.target.value)}/>
                                <br/>
                                <label htmlFor="fInput">Location</label>
                                <br/>
                                <SubInputD onChange={(e)=> setLocation(e.target.value)}>
                                {
                                    getlocation.map((item, index) =>
                                        <option key={index} value={item.id} defaultValue={getdetails.location}>{item.name}</option>
                                    )
                                }
                                </SubInputD>
                                <br/>
                                <label htmlFor="fInput">Category</label>
                                <br/>
                                <div className="f2Input-con row">
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    defaultValue={getdetails.tags}
                                    options={tags}
                                    getOptionLabel={(option) => option.name}
                                    filterSelectedOptions
                                    onChange={(event, newValue) => {setCategory(newValue.map((ele) =>{
                                        return ele.id;
                                    }))}}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Search ..."
                                        placeholder="Favorites"
                                    />
                                    )}
                                />
                                </div>
                                <br/>
                                <label htmlFor="fInput">Start Date</label>
                                <br/>
                                <input type="date" id="fInput" name="description" defaultValue={getdetails.startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                <br/>
                        </div>
                    </div>
                    </>
                    <button className="addNew-mainB" onClick={() => AddAssignment()}>Submit</button>
            </div>
		</>
	);
}

const SubInputD = styled.select`
	font-size: 15px;
	font-weight: 400;
	padding: 5px 0;
	border-radius: 5pxS;
	width: 100%;
	height: 41px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 1rem;
	@media (max-width: 480px) {

	}
`;