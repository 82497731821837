import React, { useState } from "react";
import styled from "styled-components";
import Auth from "../ApiServices/Auth";
import Modal from "react-modal";
Modal.setAppElement("#root");
export default function ResetPasswordModal(props) {
  const {
    ResetPasswordModalIsOpen,
    setResetPasswordModalIsOpen,
    setModalIsOpen,
  } = props;
  const [Password, setPassword] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const [ConfirmPassword, setConfirmPassword] = useState("");

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSendMail = async () => {
    if (Password === ConfirmPassword) {
      await Auth.ResetPassword({
        userId: 207,
        password: Password,
      });
      setResetPasswordModalIsOpen(false);
      setModalIsOpen(true);
    } else {
      alert("Please insert correct password");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMail();
  };
  return (
    <ModalS
      isOpen={ResetPasswordModalIsOpen}
      onRequestClose={() => setResetPasswordModalIsOpen(false)}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      <div>
        <Form onSubmit={handleSubmit}>
          <Label>Create New Password</Label>
          <LabelTwo>
            Your new password must be different from previously used password
          </LabelTwo>
          <FormField>
            <InputField
              type="text"
              placeholder="New Password"
              value={Password}
              onChange={handlePasswordChange}
            ></InputField>
            <InputField
              type="password"
              placeholder="Confirm Password"
              value={ConfirmPassword}
              onChange={handleConfirmPasswordChange}
            ></InputField>
          </FormField>
          <ButtonWrapper>
            <Button type="submit">Save</Button>
          </ButtonWrapper>
        </Form>
      </div>
    </ModalS>
  );
}

const ModalS = styled(Modal)`
  width: 526px;
  min-height: 450px;
  max-height: 450px;
  padding: 2em 4em 0 4em;
  border: black 1px solid;
  margin: 4em;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  margin-top: 2em;
  @media (max-width: 480px) {
    font-size: 12px;
    width: 280px;
    max-height: 350px;
    min-height: 350px;
    margin: 0;
    padding: 2em;
  }
`;

const Form = styled.form`
  text-align: center;
  z-index: 1000;
  @media (max-width: 480px) {
  }
`;

const NewAccountWrapper = styled.p`
  margin-top: 1em;
  @media (max-width: 480px) {
    height: 17px;
  }
`;

const Button = styled.button`
  padding: 0.5em 3em;
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
  background-color: #fff56c;
  margin-top: 2em;
`;

const ButtonWrapper = styled.div``;

const InputField = styled.input`
  width: 100%;
  padding: 1em;
  margin-top: 0.8em;
  border-radius: 10px;
  @media (max-width: 480px) {
    padding: 0.5em;
    margin-top: 0.2em;
  }
`;

const InputLabel = styled.label`
  font-size: 1.3em;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const FormField = styled.section`
  text-align: left;
  margin-top: 1.5em;
`;

const Label = styled.label`
  text-align: center;
  width: 100%;
  font-size: 1.9em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const LabelTwo = styled.label`
  text-align: center;
  width: 100%;
  font-size: 0.8em;
  line-height: 2em;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;
