import React, { useState, useEffect } from "react";
import {
  Nav,
  NavLink,
  NavLinkX,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavBeforeLogin,
  IMG,
} from "./NavbarElements";
import Logo from "../logo";
import LoginModal from "../LoginModal";
import RegisterModal from "../RegisterModal";
import ForgotPasswordModal from "../ForgotPasswordModal";
import SecretCodeModal from "../SecretCodeModal";
import ResetPasswordModal from "../ResetPasswordModal";
import Modal from "react-modal";
import defaultPic from "../../Assests/dp.png";
import { useHistory } from "react-router";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

Modal.setAppElement("#root");

const Navbar = () => {
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [RegisterModalIsOpen, setRegisterModalIsOpen] = useState(false);
  const [ForgotModalIsOpen, setForgotModalIsOpen] = useState(false);
  const [SecretCodeModalIsOpen, setSecretCodeModalIsOpen] = useState(false);
  const [ResetPasswordModalIsOpen, setResetPasswordModalIsOpen] =
    useState(false);
  const [SendOTPnumber, setSendOTPnumber] = useState(0);
  const [isLogged, setIsLogged] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("employeeid")) {
      setIsLogged(true);
      setModalIsOpen(false);
      history.push("/see-jobs");
    } else {
      setModalIsOpen(true);
    }
  }, [isLogged]);

  console.log(isLogged);
  // const user = localStorage.getItem('user');
  return (
    <>
      <Nav>
        <NavMenu
          className="d-flex w-100"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div style={{ display: "flex" }}>
              <Logo />
              <NavLinkX>Building Connections</NavLinkX>
            </div>
            {isLogged ? (
              <>
                <NavLink className="mr-auto" to="/add-new" exact>
                  Add new
                </NavLink>
                <NavLink to="/see-jobs" exact>
                  Posted jobs
                </NavLink>
              </>
            ) : (
              <>
                <NavBeforeLogin
                  className="mr-auto"
                  onClick={() => setModalIsOpen(true)}
                >
                  Add new
                </NavBeforeLogin>
                <NavBeforeLogin
                  className="mr-auto"
                  onClick={() => setModalIsOpen(true)}
                >
                  Posted jobs
                </NavBeforeLogin>
              </>
            )}
          </div>
          {isLogged ? (
            <>
              <IconButton
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                style={{ height: "2.7rem" }}
                onClick={handleToggle}
              >
                <IMG src={defaultPic} alt="Setugram logo"></IMG>
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={() => {
                              localStorage.removeItem("employeeid");
                              setIsLogged(false);
                              history.push("/");
                            }}
                          >
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          ) : (
            <NavBtnLink onClick={() => setModalIsOpen(true)}>Login</NavBtnLink>
          )}
          <LoginModal
            ModalIsOpen={ModalIsOpen}
            setModalIsOpen={setModalIsOpen}
            setIsLogged={setIsLogged}
            setRegisterModalIsOpen={setRegisterModalIsOpen}
            setForgotModalIsOpen={setForgotModalIsOpen}
          />
          <RegisterModal
            RegisterModalIsOpen={RegisterModalIsOpen}
            setRegisterModalIsOpen={setRegisterModalIsOpen}
            setModalIsOpen={setModalIsOpen}
          />
          <ForgotPasswordModal
            ForgotModalIsOpen={ForgotModalIsOpen}
            setForgotModalIsOpen={setForgotModalIsOpen}
            setModalIsOpen={setModalIsOpen}
            setSecretCodeModalIsOpen={setSecretCodeModalIsOpen}
            setSendOTPnumber={setSendOTPnumber}
          />
          <SecretCodeModal
            SecretCodeModalIsOpen={SecretCodeModalIsOpen}
            setSecretCodeModalIsOpen={setSecretCodeModalIsOpen}
            SendOTPnumber={SendOTPnumber}
            setResetPasswordModalIsOpen={setResetPasswordModalIsOpen}
          />
          <ResetPasswordModal
            ResetPasswordModalIsOpen={ResetPasswordModalIsOpen}
            setResetPasswordModalIsOpen={setResetPasswordModalIsOpen}
            setModalIsOpen={setModalIsOpen}
          />
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
