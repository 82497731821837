// Setugram logo for navabar
import React from "react";
import styled from "styled-components";
import logo_setugram from "../Assests/logo_strapi.png";
import { Link } from "react-router-dom";
export default function logo() {
  return (
    <ImageDiv>
      <Link to="/">
        <IMG src={logo_setugram} alt="Setugram logo"></IMG>
      </Link>
    </ImageDiv>
  );
}

const ImageDiv = styled.div`
  margin-left: 1em;
  width: 70px;
  height: 80px;
  @media (max-width: 480px) {
    width: 45px;
    height: 45px;
    margin-left: 0.2em;
  }
`;

const IMG = styled.img`
  max-height: 100%;
`;
