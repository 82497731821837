import React, { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

function ApplCard({ internship }) {
  const [intershipdetails, setIntershipDetails] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/users`)
      .then((response) => {
        setIntershipDetails(response.data);
      })
      .then((response) => response);
  }, []);

  return (
    <>
      {internship.map((item, index) => (
        <div className="Appl-card" key={index}>
          <div className="Appl-card-subcon">
            <div className="Appl-card-subco">
              <h3 className="Appl-card-heading">{item.username}</h3>
              <h3 className="Appl-card-heading2">{item.email}</h3>
              <h3 className="Appl-card-heading2">{item.contact}</h3>
            </div>

            <FaRegHeart className="Heart-toggle" />
          </div>
          <div className="Appl-card-subcon1 row">
            <p className="Appl-card-head col-sm-2">Skills :</p>
            {/* <div className="col-sm-10 Appl-card-link1"><p href="x">Heelo</p></div> */}
            {intershipdetails.length > 0 &&
              intershipdetails.map(
                (user) =>
                  user.id === item.id &&
                  user.skills.map((des, index) => (
                    <div key={index} className="col-sm-10 Appl-card-link1">
                      <p href="x">{des.name}</p>
                    </div>
                  ))
              )}
          </div>

          <div className="appId-subcon4 row">
            {item.resume && (
              <a href={`${url}${item.resume.url}`} target="_blank">
                <button className="appId-liB">Download Resume</button>
              </a>
            )}
            {item.linkedInProfile && (
              <a href={item.linkedInProfile} target="_blank">
                <button className="appId-liB">Linkedin Profile</button>
              </a>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default ApplCard;
